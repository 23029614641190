import type { AgentApiKeyType } from './enums/agent-api-key-type.enum';
import type { AgentAvailableVersionCreateDto, AgentAvailableVersionEnableDisableDto, AgentCountDto, AgentCredentialsMetadataDto, AgentDto, AgentInsertDto, AgentUpdateActiveUpdateDisplayDto, AgentUpdateDto, AgentUpdateTierDto, AgentsUiDto, GetActiveAgentUpdateInput, GetAgentTierInput, GetAgentsInput } from './models';
import type { AgentApiKey, AgentApiKeyId, AgentId } from './value-object-id/models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { SettingEntryDto } from '../application-settings/models';
import type { UserId } from '../users/value-object/models';

@Injectable({
  providedIn: 'root',
})
export class AgentService {
  apiName = 'Default';


  addVersionByCreateDto = (createDto: AgentAvailableVersionCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/agents/version',
      body: createDto,
    },
    { apiName: this.apiName,...config });


  createAgentApiKeyByAgentApiKeyTypeAndIsEnabled = (agentApiKeyType: AgentApiKeyType, isEnabled: boolean, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AgentApiKey>({
      method: 'POST',
      url: '/api/app/agents/api-keys',
      params: { agentApiKeyType, isEnabled },
    },
    { apiName: this.apiName,...config });


  deleteAgentUpdateTierByIds = (ids: number[], config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/agents/delete-tier',
      body: ids,
    },
    { apiName: this.apiName,...config });


  enableDisableByModuleVersionsEnableDto = (moduleVersionsEnableDto: AgentAvailableVersionEnableDisableDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: '/api/app/agents/version/EnableDisable',
      body: moduleVersionsEnableDto,
    },
    { apiName: this.apiName,...config });


  enableDisableUpdateByIdsAndIsUpdateEnabled = (ids: number[], isUpdateEnabled: boolean, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/agents/enable-disable-update-tier/${isUpdateEnabled}`,
      body: ids,
    },
    { apiName: this.apiName,...config });


  exportActiveAgentUpdates = (input: GetActiveAgentUpdateInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/app/agents/export-active-agents-update',
      params: { filterText: input.filterText, columnHeaders: input.columnHeaders, firstName: input.firstName, lastName: input.lastName, computerName: input.computerName, agentType: input.agentType, minTimeStamp: input.minTimeStamp, maxTimeStamp: input.maxTimeStamp, selectedRowIds: input.selectedRowIds, downloadToken: input.downloadToken, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });


  exportAgentTiers = (input: GetAgentTierInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/app/agents/export-agent-tier',
      params: { filterText: input.filterText, columnHeaders: input.columnHeaders, name: input.name, minPriority: input.minPriority, maxPriority: input.maxPriority, minNoofAgentsAssigned: input.minNoofAgentsAssigned, maxNoofAgentsAssigned: input.maxNoofAgentsAssigned, selectedRowIds: input.selectedRowIds, isUpdateEnabled: input.isUpdateEnabled, downloadToken: input.downloadToken, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });


  exportAgents = (input: GetAgentsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/app/agents/export',
      params: { filterText: input.filterText, agentIdMin: input.agentIdMin, agentIdMax: input.agentIdMax, agentRegistrationId: input.agentRegistrationId, agentType: input.agentType, firstName: input.firstName, lastName: input.lastName, computerName: input.computerName, isEnabledForDiscovery: input.isEnabledForDiscovery, isOnline: input.isOnline, minLastSeenUtc: input.minLastSeenUtc, maxLastSeenUtc: input.maxLastSeenUtc, version: input.version, agentUpdateTierName: input.agentUpdateTierName, columnHeaders: input.columnHeaders, tags: input.tags, selectedRowIds: input.selectedRowIds, localLogLevel: input.localLogLevel, remoteLogLevel: input.remoteLogLevel, userPrincipalName: input.userPrincipalName, userId: input.userId, minRemoteLogLevelExpiryTimeUtc: input.minRemoteLogLevelExpiryTimeUtc, maxRemoteLogLevelExpiryTimeUtc: input.maxRemoteLogLevelExpiryTimeUtc, minLocalLogLevelExpiryTimeUtc: input.minLocalLogLevelExpiryTimeUtc, maxLocalLogLevelExpiryTimeUtc: input.maxLocalLogLevelExpiryTimeUtc, downloadToken: input.downloadToken, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });


  exportModules = (input: GetAgentsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/app/agents/export-modules',
      params: { filterText: input.filterText, agentIdMin: input.agentIdMin, agentIdMax: input.agentIdMax, agentRegistrationId: input.agentRegistrationId, agentType: input.agentType, firstName: input.firstName, lastName: input.lastName, computerName: input.computerName, isEnabledForDiscovery: input.isEnabledForDiscovery, isOnline: input.isOnline, minLastSeenUtc: input.minLastSeenUtc, maxLastSeenUtc: input.maxLastSeenUtc, version: input.version, agentUpdateTierName: input.agentUpdateTierName, columnHeaders: input.columnHeaders, tags: input.tags, selectedRowIds: input.selectedRowIds, localLogLevel: input.localLogLevel, remoteLogLevel: input.remoteLogLevel, userPrincipalName: input.userPrincipalName, userId: input.userId, minRemoteLogLevelExpiryTimeUtc: input.minRemoteLogLevelExpiryTimeUtc, maxRemoteLogLevelExpiryTimeUtc: input.maxRemoteLogLevelExpiryTimeUtc, minLocalLogLevelExpiryTimeUtc: input.minLocalLogLevelExpiryTimeUtc, maxLocalLogLevelExpiryTimeUtc: input.maxLocalLogLevelExpiryTimeUtc, downloadToken: input.downloadToken, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });


  fetchLogsForAgentsByIdsAndFromDateAndToDate = (ids: number[], fromDate: string, toDate: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/agents/fetch-logs',
      params: { fromDate, toDate },
      body: ids,
    },
    { apiName: this.apiName,...config });


  getActiveUpdatesList = (input: GetActiveAgentUpdateInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<AgentUpdateActiveUpdateDisplayDto>>({
      method: 'GET',
      url: '/api/app/agents/get-active-updates',
      params: { filterText: input.filterText, columnHeaders: input.columnHeaders, firstName: input.firstName, lastName: input.lastName, computerName: input.computerName, agentType: input.agentType, minTimeStamp: input.minTimeStamp, maxTimeStamp: input.maxTimeStamp, selectedRowIds: input.selectedRowIds, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });


  getAgentCredentialMetadata = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, AgentCredentialsMetadataDto[]>({
      method: 'GET',
      url: '/api/app/agents/get-credentials',
    },
    { apiName: this.apiName,...config });


  getCentralMigrationSettingsByAgentId = (agentId: AgentId, config?: Partial<Rest.Config>) =>
    this.restService.request<any, SettingEntryDto[]>({
      method: 'GET',
      url: '/api/app/agents/get-central-migration-settings',
      params: { agentId },
    },
    { apiName: this.apiName,...config });


  getList = (input: GetAgentsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<AgentDto>>({
      method: 'GET',
      url: '/api/app/agents',
      params: { filterText: input.filterText, agentIdMin: input.agentIdMin, agentIdMax: input.agentIdMax, agentRegistrationId: input.agentRegistrationId, agentType: input.agentType, firstName: input.firstName, lastName: input.lastName, computerName: input.computerName, isEnabledForDiscovery: input.isEnabledForDiscovery, isOnline: input.isOnline, minLastSeenUtc: input.minLastSeenUtc, maxLastSeenUtc: input.maxLastSeenUtc, version: input.version, agentUpdateTierName: input.agentUpdateTierName, columnHeaders: input.columnHeaders, tags: input.tags, selectedRowIds: input.selectedRowIds, localLogLevel: input.localLogLevel, remoteLogLevel: input.remoteLogLevel, userPrincipalName: input.userPrincipalName, userId: input.userId, minRemoteLogLevelExpiryTimeUtc: input.minRemoteLogLevelExpiryTimeUtc, maxRemoteLogLevelExpiryTimeUtc: input.maxRemoteLogLevelExpiryTimeUtc, minLocalLogLevelExpiryTimeUtc: input.minLocalLogLevelExpiryTimeUtc, maxLocalLogLevelExpiryTimeUtc: input.maxLocalLogLevelExpiryTimeUtc, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });


  getListAgentsUpdate = (getAgentTierInput: GetAgentTierInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<AgentCountDto>>({
      method: 'GET',
      url: '/api/app/agents/get-agents-update-tier',
      params: { filterText: getAgentTierInput.filterText, columnHeaders: getAgentTierInput.columnHeaders, name: getAgentTierInput.name, minPriority: getAgentTierInput.minPriority, maxPriority: getAgentTierInput.maxPriority, minNoofAgentsAssigned: getAgentTierInput.minNoofAgentsAssigned, maxNoofAgentsAssigned: getAgentTierInput.maxNoofAgentsAssigned, selectedRowIds: getAgentTierInput.selectedRowIds, isUpdateEnabled: getAgentTierInput.isUpdateEnabled, sorting: getAgentTierInput.sorting, skipCount: getAgentTierInput.skipCount, maxResultCount: getAgentTierInput.maxResultCount },
    },
    { apiName: this.apiName,...config });


  getListOfAgents = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<AgentsUiDto>>({
      method: 'GET',
      url: '/api/app/agents/get-list-of-agents',
    },
    { apiName: this.apiName,...config });


  getListOfAgentsById = (agentTierId: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<AgentsUiDto>>({
      method: 'GET',
      url: '/api/app/agents/get-list-of-agents-by-id',
      params: { agentTierId },
    },
    { apiName: this.apiName,...config });


  getModulesList = (input: GetAgentsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<AgentDto>>({
      method: 'GET',
      url: '/api/app/agents/get-modules',
      params: { filterText: input.filterText, agentIdMin: input.agentIdMin, agentIdMax: input.agentIdMax, agentRegistrationId: input.agentRegistrationId, agentType: input.agentType, firstName: input.firstName, lastName: input.lastName, computerName: input.computerName, isEnabledForDiscovery: input.isEnabledForDiscovery, isOnline: input.isOnline, minLastSeenUtc: input.minLastSeenUtc, maxLastSeenUtc: input.maxLastSeenUtc, version: input.version, agentUpdateTierName: input.agentUpdateTierName, columnHeaders: input.columnHeaders, tags: input.tags, selectedRowIds: input.selectedRowIds, localLogLevel: input.localLogLevel, remoteLogLevel: input.remoteLogLevel, userPrincipalName: input.userPrincipalName, userId: input.userId, minRemoteLogLevelExpiryTimeUtc: input.minRemoteLogLevelExpiryTimeUtc, maxRemoteLogLevelExpiryTimeUtc: input.maxRemoteLogLevelExpiryTimeUtc, minLocalLogLevelExpiryTimeUtc: input.minLocalLogLevelExpiryTimeUtc, maxLocalLogLevelExpiryTimeUtc: input.maxLocalLogLevelExpiryTimeUtc, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });


  getUpdateTierList = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<AgentUpdateTierDto>>({
      method: 'GET',
      url: '/api/app/agents/get-update-tier',
    },
    { apiName: this.apiName,...config });


  insertAgentApiByInput = (input: AgentInsertDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/agents/create',
      body: input,
    },
    { apiName: this.apiName,...config });


  loadNlogLevels = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, string[]>({
      method: 'GET',
      url: '/api/app/agents/load-logs',
    },
    { apiName: this.apiName,...config });


  queueAgentUpdateByAgentIds = (agentIds: AgentId[], config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/agents/queue-agent-update',
      body: agentIds,
    },
    { apiName: this.apiName,...config });


  setAgentApiKeyStatus = (agentApiKeyId: AgentApiKeyId, isEnabled: boolean, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/agents/api-keys/{agentApiKeyId}`,
      params: { isEnabled },
      body: agentApiKeyId,
    },
    { apiName: this.apiName,...config });


  setLogLevelByAgentIdsAndLocalLogLevelAndLocalLogLevelExpiryTimeUtcAndRemoteLogLevelAndRemoteLogLevelExpiryTimeUtc = (agentIds: AgentId[], localLogLevel: string, localLogLevelExpiryTimeUtc: string, remoteLogLevel: string, remoteLogLevelExpiryTimeUtc: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/agents/set-log-level',
      params: { localLogLevel, localLogLevelExpiryTimeUtc, remoteLogLevel, remoteLogLevelExpiryTimeUtc },
      body: agentIds,
    },
    { apiName: this.apiName,...config });


  startDiscoveryByUserIds = (userIds: UserId[], config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/agents/start-discovery',
      body: userIds,
    },
    { apiName: this.apiName,...config });


  storeCentralMigrationSettingsBySettingEntriesAndAgentId = (settingEntries: SettingEntryDto[], agentId: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/agents/set-central-migration-settings/${agentId}`,
      body: settingEntries,
    },
    { apiName: this.apiName,...config });


  updateAgentTierByInput = (input: AgentUpdateDto[], config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/agents/update-agents',
      body: input,
    },
    { apiName: this.apiName,...config });

    updateCommandRetrievalForAgentsByIdsAndEnableCommandRetrieval = (ids: number[], enableCommandRetrieval: boolean, config?: Partial<Rest.Config>) =>
      this.restService.request<any, void>({
        method: 'POST',
        url: `/api/app/agents/enable-disable-commandretrieval/${enableCommandRetrieval}`,
        body: ids,
      },
      { apiName: this.apiName,...config });

  updateDiscoveryForAgentsByIdsAndIsEnabledForDiscovery = (ids: number[], isEnabledForDiscovery: boolean, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/agents/enable-disable-discovery/${isEnabledForDiscovery}`,
      body: ids,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
